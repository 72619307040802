/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { logout, isLoggedIn } from "../../utils/auth"
import "./typography.css"
import "./layout.css"
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import BitMenu from './menu'

let theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#00D1BB',
    },
    secondary: {
      main: '#00D1BB',
    },
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    h1: {
      fontFamily: 'skumfuk'
    },
    h2: {
      fontFamily: 'Mako-Regular'
    },
    h3: {
      fontFamily: 'Mako-Regular'
    },
    h4: {
      fontFamily: 'Mako-Regular'
    },
    body1: {
      fontWeight: 400,
      color: 'rgba(255,255,255,0.9)',
    },
    body2: {
      fontWeight: 400,
      color: 'rgba(255,255,255,0.9)',
    },
    button: {
      fontFamily: 'skumfuk',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: 'black',
      },
    },
  },
});
theme = responsiveFontSizes(theme);
const Layout = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <BitMenu />
        <div className="container">
          <main>{children}</main>
          {/* <footer
            style={{
              marginTop: `2rem`,
            }}
          >
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </footer> */}
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
