import React from "react"
import { getUser, isLoggedIn, logout } from "../../utils/auth"
import Button from '../Form/Button';
import { navigate, Link} from 'gatsby'
import BitAvatar from '../Profile/avatar';

export default () => {

  let details;
  if (!isLoggedIn()) {
    details = (
      <span>
        <Link to="/login/"> <Button color="secondary" text="Login" /></Link>
      </span>
    )
  } else {
    const { displayName, email } = getUser()
    details = (
      <BitAvatar/>
    )
  }

  return <div>{details}</div>
}
