import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styled2 from 'styled-components'

let gradient = (color) => {
  switch(color) {
    case 'primary':
      color = 'linear-gradient(224deg, #00D1BB 6%, #0DFF15 100%)'
      break;

    case 'secondary':
      color = 'linear-gradient(226deg, #FF940D 0%, #FF00C8 100%)'
      break;

    case 'tertiary' || 'inherit':
      color = 'linear-gradient(226deg, rgba(0,0,0,0.1) 0%, rgba(24,25,26, 0.2) 100%)'
      break;
  }
  return color;
}

const MyButton = styled(Button)({
  background: (props) =>
    gradient(props.color),
  border: 0,
  borderRadius: 0,
  color: 'white',
  height: 48,
  textShadow: '1px 1px rgb(0,0,0,0.45)',
  padding: '0 40px',
  clipPath: 'polygon(0 0, 100% 0, 100% 88%, 0% 100%)',
  margin: '25px 10px 10px 0',
});

const MySpan = styled2.div`
  filter: drop-shadow(0px 20px 20px rgba(0, 255, 229, 0.2));
  text-align: center;
`

const StyledButton = (props) => {
  return <MySpan><MyButton size={props.size} onClick={props.onClick} color={props.color} fullWidth={props.fullWidth} disabled={props.disabled}>{props.text}</MyButton></MySpan>;
}

StyledButton.defaultProps = {
  text: "Submit",
  color: "primary",
  fullWidth: false,
  disabled: false,
  size: 'medium'
}

export default StyledButton