import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Status from './status'
import HeaderLogo from "../SVG/navHeader"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'none',
    paddingLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: 'rgba(0,0,0,0.2)', boxShadow: 'none', padding: '0', color: 'rgba(255,255,255,0.7)'}}>
        <Toolbar style={{display: `flex`, justifyContent: 'space-between', paddingLeft: '0'}}>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <HeaderLogo style={{maxWidth: '600px', minWidth: '200px'}} />
          <span>
            <Status />
          </span>
        </Toolbar>
      </AppBar>
    </div>
  );
}