import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getUser, isLoggedIn, logout } from "../../utils/auth"
import useFirebase from '../../utils/useFirebase';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { navigate, useStaticQuery, graphql } from "gatsby"

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    cursor: 'pointer',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function ImageAvatars() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const data = useStaticQuery(avatarImg)

  const firebase = useFirebase()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        onClick={handleClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar style={{ display: `block` }} src={data.avatarDefault.childImageSharp.fixed.src} />
      </StyledBadge>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate(`/app/profile/`)}>Profile</MenuItem>
        <MenuItem onClick={event => { event.preventDefault(); logout(firebase).then(() => navigate(`/login/`)) }}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const avatarImg = graphql`
  query avatarImg {
    avatarDefault: file(relativePath: { eq: "profileDefault.png" }) {
      childImageSharp {
        fixed(quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`